<template>
  <v-form class="px-5" ref="form" v-model="valid" lazy-validation>
    <v-row class="mt-4">
      <v-col>
        <div class="mb-2">Meter Type</div>
        <v-btn-toggle v-model="form.meterType">
          <v-btn @click="showMetersForm(9)" value="9">
            <v-icon color="amber">mdi-lightbulb</v-icon>
          </v-btn>
          <v-btn @click="showMetersForm(10)" value="10">
            <v-icon color="red">mdi-fire</v-icon>
          </v-btn>
          <v-btn @click="showMetersForm(2705)" value="2705">
            <v-icon color="blue">mdi-water</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row v-if="showInfoButtons">
      <v-col>
        <div class="mb-2">Information Obtained</div>
        <v-btn-toggle v-model="form.infoObtained" tile>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" @click="infoObtained" value="normal">
                <v-icon color="primary">mdi-check-bold</v-icon>
              </v-btn>
            </template>
            <span>Actual</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" @click="getInfo" value="estimate">
                <v-icon color="primary">mdi-help</v-icon>
              </v-btn>
            </template>
            <span>Estimate</span>
          </v-tooltip>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row v-if="showInfoObtained">
      <v-col>
        <SupplyNumber class="mb-2" :type="form.meterType === '9'
          ? 'electricity'
          : form.meterType === '2705'
            ? 'water'
            : 'gas'
          "></SupplyNumber>

        <span v-if="form.meterType === '9' &&
          showSupplyNumberValidationError &&
          pcLengthError &&
          (Object.keys(supplyDetails).length === 0 ||
            !supplyDetails.pc ||
            (supplyDetails.pc && supplyDetails.pc.length < 2))
          " class="mt-1 d-block">
          <span class="red--text"> PC length must be 2. </span>
        </span>

        <span v-if="form.meterType === '9' &&
          showSupplyNumberValidationError &&
          mtcLengthError &&
          (Object.keys(supplyDetails).length === 0 ||
            !supplyDetails.mtc ||
            (supplyDetails.mtc && supplyDetails.mtc.length < 3))
          " class="mt-1 d-block">
          <span class="red--text"> MTC length must be 3. </span>
        </span>

        <span v-if="form.meterType === '9' &&
          showSupplyNumberValidationError &&
          llfLengthError &&
          (Object.keys(supplyDetails).length === 0 ||
            !supplyDetails.llf ||
            (supplyDetails.llf && supplyDetails.llf.length < 3))
          " class="mt-1 d-block">
          <span class="red--text"> LLF length must be 3. </span>
        </span>

        <span v-if="form.meterType === '9' && showLessThan13SupplyNumberValidationError
          " class="mt-1 d-block">
          <span class="red--text"> Supply Number must be at least 13. </span>
        </span>

        <span v-if="form.meterType !== '9' &&
          showSupplyNumberValidationError &&
          !isFullSupplyNumberEntered
          " class="mt-2">
          <span class="red--text"> Please enter full supply number </span>
        </span>
        <span v-if="form.meterType !== '9' &&
          showSupplyNumberValidationError &&
          isFullSupplyNumberEntered
          " class="mt-2">
          <span class="red--text"> Please enter full supply number </span>
        </span>
        <span v-if="showInvalidSupplyNumberFormatError && form.meterType !== '2705'" class="mt-2">
          <span class="red--text"> Invalid format entered. </span>
        </span>

        <span v-if="showMinSupplyNumberValidationError" class="mt-2">
          <span class="red--text">
            Supply number must be at least 6 digits
          </span>
        </span>

        <span class="mt-2" v-if="msgError">
          <span class="red--text" v-html="msgError">{{ this.msgError }}</span>
        </span>
        <v-select class="mt-4" label="Current Supplier" ref="selectedSupplierId" v-model="selectedSupplierId"
          item-text="text" item-value="value" :items="filteredSuppliers" :rules="supplierRules"
          @change="selectSupplier(selectedSupplierId)">
        </v-select>
        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
          min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field type="tel" label="Contract End Date" class="d-inline-block mr-2" placeholder="DD/MM/YYYY"
              v-mask="'##/##/####'" ref="contractEndDate" v-model="contractEndDate" :rules="cedRules"
              @keyup="contractEndDateChanged"></v-text-field>
            <v-icon class="d-inline-block mr-2" v-bind="attrs" v-on="on">
              mdi-calendar
            </v-icon>
          </template>
          <v-date-picker ref="picker" v-model="form.contractEndDate" min="1950-01-01" locale="en-GB"
            @change="formContractEndDateChanged"></v-date-picker>
        </v-menu>
        <!-- <v-divider v-if="selectedMeterTypeId !== 2705" class="mt-2"></v-divider>
        <v-row v-if="selectedMeterTypeId !== 2705" class="mt-3">
          <h3
            class="v-list-item__title text-h5 text-capitalize font-weight-medium ml-3 mb-3"
            style="font-weight: bold; font-size: 17px !important;"
          >
            Meter Documents
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="primary ml-3"
                  style="font-size:20px;"
                  >mdi-information</v-icon
                >
              </template>
              <span
                >Please ensure that you only add or edit meters with the correct
                meter documents attached. <br />
                All adding and editing of meters are monitored regularly and
                misuse can result in
                <span class="font-weight-medium">
                  disciplinary action.
                </span></span
              >
            </v-tooltip>
          </h3>
          <v-col cols="12" class="py-0">
            <div class="data-displayer">
              <v-text-field
                ref="description"
                v-model="description"
                :rules="descriptionRules"
                label="Description"
                size="sm"
              ></v-text-field>
            </div>
          </v-col>
          <v-col cols="12" class="py-0">
            <div class="data-displayer">
              <v-select
                ref="uploadSelected"
                v-model="uploadSelected"
                size="sm"
                :rules="uploadSelectedRules"
                :items="uploads"
                placeholder="Please Select"
                label="Upload Type"
              >
                <template slot="item" slot-scope="data">
                  {{ data.item.text }}
                  <v-chip
                    v-if="data.item.valid"
                    class="ma-2"
                    x-small
                    color="green"
                    text-color="white"
                  >
                    VALID
                  </v-chip>
                </template>
              </v-select>
            </div>
          </v-col>
          <v-col cols="12" class="">
            <v-file-input
              ref="file"
              label="Choose a file"
              id="file"
              v-model="file"
            ></v-file-input>
       
          </v-col>
        </v-row> -->
      </v-col>
    </v-row>
    <v-row v-if="showGetInfo">
      <v-col>
        <v-select :items="filteredSuppliers" label="Current Supplier" ref="selectedSupplierId"
          v-model="selectedSupplierId" item-text="text" item-value="value" :rules="supplierRules"
          @change="selectSupplier(selectedSupplierId)">
        </v-select>
        <v-radio-group v-model="radios" mandatory row>
          <v-radio @click="showEndDate" label="End" value="radio-1"></v-radio>
          <v-radio @click="showStartDate" label="Start" value="radio-2"></v-radio>
        </v-radio-group>
        <div v-if="showEnd">
          <v-menu ref="menuEnd" v-model="menuEnd" :close-on-content-click="false" transition="scale-transition" offset-y
            min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="d-inline-block mr-2" v-bind="attrs" v-on="on">
                mdi-calendar
              </v-icon>
              <v-text-field type="tel" label="Contract End Date" class="d-inline-block mr-2" placeholder="DD/MM/YYYY"
                v-mask="'##/##/####'" ref="contractEndDateEstimate" :rules="cedRules" v-model="contractEndDateEstimate"
                @keyup="contractEndDateEstimateChanged"></v-text-field>
            </template>
            <v-date-picker ref="picker" v-model="form.contractEndDateEstimate" min="1950-01-01" locale="en-GB"
              @change="formContractEndDateEstimateChanged"></v-date-picker>
          </v-menu>
        </div>
        <div v-if="showStart">
          <v-menu ref="menuStart" v-model="menuStart" :close-on-content-click="true" transition="scale-transition"
            offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="d-inline-block mr-2" v-bind="attrs" v-on="on">
                mdi-calendar
              </v-icon>
              <v-text-field type="tel" v-model="contractStartDate" ref="contractStartDate" :rules="csdRules"
                label="Contract Start Date" class="d-inline-block mr-2" placeholder="DD/MM/YYYY" v-mask="'##/##/####'"
                @keyup="contractStartDateChanged"></v-text-field>
            </template>
            <v-date-picker ref="picker" v-model="form.contractStartDate" min="1950-01-01"
              @change="formContractStartDateChanged" locale="en-GB"></v-date-picker>
          </v-menu>
          <div v-if="showStart">
            <v-text-field v-model="termInMonths" max="60" type="number" label="Contract Term (Months)"></v-text-field>
            <div class="mb-2">Contract End Date</div>
            <v-text-field v-model="ced" disabled></v-text-field>
          </div>
        </div>
      </v-col>
    </v-row>
    <div v-if="showInfoObtained || showGetInfo" class="mt-4">
      <v-btn depressed color="accent" @click="validateAndSubmitForm" block dark large>
        Add Meter
      </v-btn>
    </div>
    <v-row> </v-row>
  </v-form>
</template>
<script>
import { mapGetters, mapActions } from "vuex"
import { mask } from "vue-the-mask"

import { changesSaved, somethingWentWrong } from "@/helpers/utilities"
import SupplyNumber from "@/components/supply-number"
import api from "@/store/api"
import dayjs from "dayjs"

export default {
  name: "AddMeterInformation",
  data() {
    return {
      showLessThan13SupplyNumberValidationError: false,
      pcLengthError: false,
      mtcLengthError: false,
      llfLengthError: false,
      showSupplyNumberValidationError: false,
      showMinSupplyNumberValidationError: false,
      contractEndDate: "",
      contractEndDateEstimate: "",
      contractStartDate: "",
      selectedSupplierId: 0,
      radios: null,
      valid: null,
      termInMonths: null,
      endDate: "",
      menu: false,
      menuStart: false,
      menuEnd: false,
      form: {
        meterType: "",
        infoObtained: "",
        addressId: "",
        cotDate: "",
        contractStartDate: "",
        contractEndDate: "",
        contractEndDateEstimate: "",
        supplyNumb: ""
      },
      column: null,
      text: "center",
      supplier: "Select Supplier",
      suppliers: [],
      allSuppliers: [],
      showInfoButtons: false,
      showInfoObtained: false,
      showGetInfo: false,
      showEnd: true,
      showStart: false,
      msgError: "",
      formHasErrors: false,
      selectedSupplier: {
        supplierId: 0,
        supplierName: "",
        active: false,
        internalLogo: "",
        meterType: [],
        electricity: 0,
        gas: 0,
        customerTelephone: "",
        brokerSupportTelephone: "",
        supplierWebsite: "",
        terminationEmail: "",
        cotEmail: "",
        generalEmail: "",
        uhtpiAgreement: true,
        uhDirect: true,
        uhSubBroker: false,
        csstpiAgreement: false,
        cssDirect: false,
        cssSubBroker: false
      },
      descriptionRules: [value => !!value || "Please Add a Description"],
      uploadSelectedRules: [value => !!value || "Please Select"],
      fileRules: [value => !!value || "Please Upload a File"],
      uploadSelected: 0,
      file: "",
      uploads: [],
      meterId: null,
      selectedMeterTypeId: null
    }
  },
  directives: { mask },

  components: { SupplyNumber },
  whatch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"))
    }
  },
  computed: {
    ...mapGetters("forms", ["supplyDetails"]),
    ...mapGetters("addresses", ["selectedAddress"]),
    ...mapGetters("employee", ["userId"]),
    ...mapGetters("ui", ["formEditMode"]),
    ...mapGetters("company", ["companyId"]),

    filteredSuppliers() {
      const filteredSuppliersArray = this.suppliers.map(option => ({
        text: option.supplierName,
        value: option.supplierId
      }))

      return filteredSuppliersArray
    },
    // gasSNRules(){
    //  return [
    //      v => (v && v.length > 5) || 'Meter must have at least 6 digits.'
    //  ]
    // },
    isFullSupplyNumberEntered() {
      const tmpSupplyNumber =
        this.supplyDetails.sId +
        this.supplyDetails.mpIdA +
        this.supplyDetails.mpIdB +
        this.supplyDetails.cd
      if (tmpSupplyNumber.length < 13) {
        return false
      }
      return true
    },
    showInvalidSupplyNumberFormatError() {
      if (!this.supplyDetails || !this.supplyDetails.showInvalidFormatError)
        return false
      return this.supplyDetails.showInvalidFormatError
    },
    ced() {
      if (
        this.form.contractStartDate === "" ||
        this.form.contractStartDate === 0
      )
        return ""
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.form.contractEndDateEstimate = dayjs(this.form.contractStartDate)
        .add(this.termInMonths, "months")
        .format("YYYY-MM-DD")
      return dayjs(this.form.contractStartDate)
        .add(this.termInMonths, "months")
        .subtract(1, "day")
        .format("DD/MM/YYYY")
    },
    formattedDate() {
      return this.form.cotDate
        ? dayjs(this.form.cotDate).format("DD/MM/YYYY")
        : ""
    },

    // filteredSuppliers() {
    //   if (this.form.meterType === "9") {
    //     const filteredSuppliersArray = this.suppliers.filter(
    //       supplier => supplier.electricity === 1 && supplier.active === true
    //     )
    //     return filteredSuppliersArray.map(option => ({
    //       text: option.supplierName,
    //       value: option.supplierId
    //     }))
    //   }
    //   const filteredSuppliersArray = this.suppliers.filter(
    //     supplier => supplier.gas === 1 && supplier.active === true
    //   )
    //   return filteredSuppliersArray.map(option => ({
    //     text: option.supplierName,
    //     value: option.supplierId
    //   }))
    // },
    formattedStartDate() {
      return this.form.contractStartDate
        ? dayjs(this.form.contractStartDate).format("DD/MM/YYYY")
        : ""
    },
    formattedEndDate() {
      return this.form.contractEndDate
        ? dayjs(this.form.contractEndDate).format("DD/MM/YYYY")
        : ""
    },
    supplierRules() {
      return [value => !!value || "Please Select a Supplier"]
    },
    cedRules() {
      return [value => !!value || "Please enter a Contract End Date"]
    },
    csdRules() {
      return [value => !!value || "Please enter a Contract Start Date"]
    }
  },
  created() { },
  mounted() {
    this.fetchSuppliers()
    this.getContractUploadTypes()
  },
  watch: {
    termInMonths(newVal) {
      if (parseInt(newVal, 10) > 60) {
        this.termInMonths = "60"
      }
    }
  },
  methods: {
    ...mapActions("meters", [
      "fetchMetersAfterRemove",
      "fetchPrimaryMetersAfterSave"
    ]),
    ...mapActions("notes", ["fetchNotes"]),
    ...mapActions("ui", ["closeForm"]),
    ...mapActions("forms", ["fetchMainData"]),

    async getContractUploadTypes() {
      const res = await api.get("getMeterDocuementType")
      console.log("meter typ docs", res)
      if (res && res.status === 200) {
        // this.uploads = res.data.filter(x => {
        //   return x.valid
        // })
        // this.uploads = res.data.filter(option => {
        //   return option.customerOption
        // })

        this.uploads = res.data.map(option => ({
          text: option.value,
          value: option.id,
          valid: option.valid
        }))
      }
    },
    // isSelectedDocumentValid() {
    //   var uploadType = this.uploads.find(x => x.value == this.uploadSelected)
    //   // console.log(uploadType)
    //   return uploadType === undefined ? false : uploadType.valid
    // },
    async fetchSuppliers() {
      const res = await api.get(`suppliers/${this.selectedMeterTypeId}`)
      console.log(res)
      if (res.status === 200) {
        this.suppliers = res.data
      }
    },
    showMetersForm(id) {
      this.selectedMeterTypeId = id
      this.showInfoButtons = true
      this.fetchSuppliers()
    },
    contractEndDateEstimateChanged() {
      if (this.contractEndDateEstimate.length >= 1) {
        const tempDay = this.contractEndDateEstimate.slice(0, 2)
        const tempMonth = this.contractEndDateEstimate.slice(3, 5)

        if (parseInt(tempDay) > 31) {
          const temp = this.contractEndDateEstimate.slice(2, 10)
          this.contractEndDateEstimate = "31" + temp
        }
        if (
          this.contractEndDateEstimate.length >= 2 &&
          parseInt(tempDay) === 0
        ) {
          const temp = this.contractEndDateEstimate.slice(2, 10)
          this.contractEndDateEstimate = "01" + temp
        }
        if (parseInt(tempMonth) > 12) {
          const temp = this.contractEndDateEstimate.slice(5, 10)
          this.contractEndDateEstimate = tempDay + "/12" + temp
        }
        if (
          this.contractEndDateEstimate.length >= 5 &&
          parseInt(tempMonth) === 0
        ) {
          const temp = this.contractEndDateEstimate.slice(5, 10)
          this.contractEndDateEstimate = tempDay + "/01" + temp
        }
        const contractEndDateEstimate = this.contractEndDateEstimate
          .split("/")
          .reverse()
          .join("-")
        const currentDOB = dayjs(contractEndDateEstimate).format("YYYY-MM-DD")

        this.form.contractEndDateEstimate = currentDOB
        if (this.form.contractEndDateEstimate === "Invalid Date") {
          this.form.contractEndDateEstimate = ""
        }
      }
    },
    contractStartDateChanged() {
      if (this.contractStartDate.length >= 1) {
        const tempDay = this.contractStartDate.slice(0, 2)
        const tempMonth = this.contractStartDate.slice(3, 5)

        if (parseInt(tempDay) > 31) {
          const temp = this.contractStartDate.slice(2, 10)
          this.contractStartDate = "31" + temp
        }
        if (this.contractStartDate.length >= 2 && parseInt(tempDay) === 0) {
          const temp = this.contractStartDate.slice(2, 10)
          this.contractStartDate = "01" + temp
        }
        if (parseInt(tempMonth) > 12) {
          const temp = this.contractStartDate.slice(5, 10)
          this.contractStartDate = tempDay + "/12" + temp
        }
        if (this.contractStartDate.length >= 5 && parseInt(tempMonth) === 0) {
          const temp = this.contractStartDate.slice(5, 10)
          this.contractStartDate = tempDay + "/01" + temp
        }
        const contractStartDate = this.contractStartDate
          .split("/")
          .reverse()
          .join("-")
        const currentDOB = dayjs(contractStartDate).format("YYYY-MM-DD")

        this.form.contractStartDate = currentDOB
        if (this.form.contractStartDate === "Invalid Date") {
          this.form.contractStartDate = ""
        }
      }
    },
    contractEndDateChanged() {
      if (this.contractEndDate.length >= 1) {
        const tempDay = this.contractEndDate.slice(0, 2)
        const tempMonth = this.contractEndDate.slice(3, 5)

        if (parseInt(tempDay) > 31) {
          const temp = this.contractEndDate.slice(2, 10)
          this.contractEndDate = "31" + temp
        }
        if (this.contractEndDate.length >= 2 && parseInt(tempDay) === 0) {
          const temp = this.contractEndDate.slice(2, 10)
          this.contractEndDate = "01" + temp
        }
        if (parseInt(tempMonth) > 12) {
          const temp = this.contractEndDate.slice(5, 10)
          this.contractEndDate = tempDay + "/12" + temp
        }
        if (this.contractEndDate.length >= 5 && parseInt(tempMonth) === 0) {
          const temp = this.contractEndDate.slice(5, 10)
          this.contractEndDate = tempDay + "/01" + temp
        }
        const contractEndDate = this.contractEndDate
          .split("/")
          .reverse()
          .join("-")
        const currentDOB = dayjs(contractEndDate).format("YYYY-MM-DD")

        this.form.contractEndDate = currentDOB
        if (this.form.contractEndDate === "Invalid Date") {
          this.form.contractEndDate = ""
        }
      }
    },
    formContractEndDateChanged() {
      this.contractEndDate = dayjs(this.form.contractEndDate).format(
        "DD/MM/YYYY"
      )
    },
    formContractEndDateEstimateChanged() {
      this.contractEndDateEstimate = dayjs(
        this.form.contractEndDateEstimate
      ).format("DD/MM/YYYY")
    },
    formContractStartDateChanged() {
      this.contractStartDate = dayjs(this.form.contractStartDate).format(
        "DD/MM/YYYY"
      )
    },

    resetForm() {
      this.file = ""
      this.pcLengthError = false
      this.mtcLengthError = false
      this.llfLengthError = false
      this.showSupplyNumberValidationError = false
      this.showMinSupplyNumberValidationError = false
      this.contractEndDate = ""
      this.contractEndDateEstimate = ""
      this.contractStartDate = ""
      this.selectedSupplierId = 0
      this.radios = null
      this.valid = null
      this.termInMonths = null
      this.endDate = ""
      this.menu = false
      this.menuStart = false
      this.menuEnd = false
      this.form = {
        meterType: "",
        infoObtained: "",
        addressId: "",
        cotDate: "",
        contractStartDate: "",
        contractEndDate: "",
        contractEndDateEstimate: "",
        supplyNumb: ""
      }
      this.column = null
      this.text = "center"
      this.supplier = "Select Supplier"
      // this.suppliers = []
      this.allSuppliers = []
      this.showInfoButtons = false
      this.showInfoObtained = false
      this.showGetInfo = false
      this.showEnd = true
      this.showStart = false
      this.msgError = ""
      this.formHasErrors = false
      this.selectedSupplier = {
        supplierId: 0,
        supplierName: "",
        active: false,
        internalLogo: "",
        meterType: [],
        electricity: 0,
        gas: 0,
        customerTelephone: "",
        brokerSupportTelephone: "",
        supplierWebsite: "",
        terminationEmail: "",
        cotEmail: "",
        generalEmail: "",
        uhtpiAgreement: true,
        uhDirect: true,
        uhSubBroker: false,
        csstpiAgreement: false,
        cssDirect: false,
        cssSubBroker: false
      }
    },

    showEndDate() {
      this.$refs.form.resetValidation()
      if (this.showEnd === false) {
        this.showEnd = true
        this.showStart = false
      } else {
        this.showEnd = false
      }
    },
    showStartDate() {
      this.$refs.form.resetValidation()
      if (this.showStart === false) {
        this.showStart = true
        this.showEnd = false
      } else {
        this.showStart = false
      }
    },
    infoObtained() {
      if (this.showInfoObtained === false) {
        this.showInfoObtained = true
        this.showGetInfo = false
      } else {
        this.showInfoObtained = false
      }
    },
    getInfo() {
      if (this.showGetInfo === false) {
        this.showGetInfo = true
        this.showInfoObtained = false
      } else {
        this.showGetInfo = false
      }
    },
    validate() {
      this.$refs.form.validate()
    },
    save(date) {
      this.$refs.menu.save(date)
      this.$refs.menuStart.save(date)
      this.$refs.menuEnd.save(date)
    },
    validateAndSubmitForm() {
      this.showSupplyNumberValidationError = false
      this.showMinSupplyNumberValidationError = false

      if (
        this.showInfoObtained &&
        this.form.meterType === "9" &&
        !Object.keys(this.supplyDetails)
      ) {
        this.pcLengthError = true
        this.mtcLengthError = true
        this.llfLengthError = true
      }

      this.showLessThan13SupplyNumberValidationError = false
      if (this.showInfoObtained && this.form.meterType === "9") {
        const tmpSupplyNumber =
          this.supplyDetails.sId +
          this.supplyDetails.mpIdA +
          this.supplyDetails.mpIdB +
          this.supplyDetails.cd
        if (tmpSupplyNumber.length < 13) {
          this.showLessThan13SupplyNumberValidationError = true
        }
      }

      // Validate Supply Number
      if (
        this.showInfoObtained &&
        (!Object.keys(this.supplyDetails) ||
          (this.form.meterType === "9" &&
            (Object.keys(this.supplyDetails).length === 0 ||
              this.supplyDetails.pc === "" ||
              this.supplyDetails.mtc === "" ||
              this.supplyDetails.llf === "" ||
              this.supplyDetails.pc?.length < 2 ||
              this.supplyDetails.mtc?.length < 3 ||
              this.supplyDetails.llf?.length < 3 ||
              this.supplyDetails.sId === "" ||
              this.supplyDetails.mpIdA === "" ||
              this.supplyDetails.mpIdB === "" ||
              this.supplyDetails.cd === "" ||
              this.supplyDetails.cd?.length < 3)) ||
          (this.form.meterType !== "9" &&
            (Object.keys(this.supplyDetails).length === 0 ||
              this.supplyDetails.supplyNumber === "" ||
              this.supplyDetails.electricSupplyNo === "")))
      ) {
        this.showSupplyNumberValidationError = true
        this.pcLengthError = false
        this.mtcLengthError = false
        this.llfLengthError = false
        if (Object.keys(this.supplyDetails) && this.form.meterType === "9") {
          if (
            Object.keys(this.supplyDetails).length === 0 ||
            (this.form.meterType === "9" && this.supplyDetails.pc?.length < 2)
          ) {
            this.pcLengthError = true
          }
          if (
            Object.keys(this.supplyDetails).length === 0 ||
            (this.form.meterType === "9" && this.supplyDetails.mtc?.length < 3)
          ) {
            this.mtcLengthError = true
          }

          if (
            Object.keys(this.supplyDetails).length === 0 ||
            (this.form.meterType === "9" && this.supplyDetails.llf?.length < 3)
          ) {
            this.llfLengthError = true
          }
        }

        return false
      }



      if (this.showInfoObtained && this.form.meterType === "9") {
        const tmpSupplyNumber =
          this.supplyDetails.sId +
          this.supplyDetails.mpIdA +
          this.supplyDetails.mpIdB +
          this.supplyDetails.cd
        if (tmpSupplyNumber.length < 13) {
          return false
        }
      }

      if (
        this.showInfoObtained &&
        (this.contractEndDate === "" || this.selectedSupplierId === 0)
      ) {
        const form = {
          contractEndDate: this.contractEndDate,
          selectedSupplierId: this.selectedSupplierId
        }
        this.formHasErrors = false

        Object.keys(form).forEach(f => {
          if (!this.form[f]) this.formHasErrors = true

          this.$refs[f].validate(true)
        })
        return false
      }

      if (
        this.showGetInfo &&
        ((this.showStart && this.contractStartDate === "") ||
          (this.showEnd && this.contractEndDateEstimate === "") ||
          this.selectedSupplierId === 0)
      ) {
        const form = {
          selectedSupplierId: this.selectedSupplierId,
          contractEndDateEstimate: this.contractEndDateEstimate,
          contractStartDate: this.contractStartDate
        }
        this.formHasErrors = false

        Object.keys(form).forEach(f => {
          if (!this.form[f]) this.formHasErrors = true

          this.$refs[f].validate(true)
        })
        return false
      }

      if (
        this.form.meterType !== "9" &&
        Object.keys(this.supplyDetails).length > 0 &&
        this.supplyDetails.supplyNumber.length < 6
      ) {
        this.showMinSupplyNumberValidationError = true
        return false
      }

      this.submitForm()
    },
    async submitForm() {
      // const supplierId = this.selectedSupplierId
      if (
        this.form.infoObtained.includes("normal") &&
        !this.selectedSupplierId
      ) {
        const form = {
          supplierId: this.selectedSupplierId,
          contractEndDate: this.form.contractEndDate
        }
        this.formHasErrors = false
        Object.keys(form).forEach(f => {
          if (!this.form[f]) this.formHasErrors = true
          this.$refs[f].validate(true)
        })
        return false
      }

      const meterNumber =
        this.form.meterType === "9"
          ? this.supplyDetails.pc +
          " " +
          this.supplyDetails.mtc +
          "" +
          this.supplyDetails.llf +
          `<br/>` +
          this.supplyDetails.sId +
          "" +
          this.supplyDetails.mpIdA +
          "" +
          this.supplyDetails.mpIdB +
          "" +
          this.supplyDetails.cd
          : this.supplyDetails.supplyNumber

      // const uploadType = this.uploads.find(x => x.value == this.uploadSelected)

      let tmpNoteMessage = ""
      if (this.selectedMeterTypeId !== 2705) {
        tmpNoteMessage = !this.form.infoObtained.includes("estimate")
          ? `Meter Added - ${meterNumber} <br/>
                                      Supplier - ${this.selectedSupplier.supplierName
          } <br/>
                                      CED - ${dayjs(
            this.form.contractEndDate
          ).format("DD/MM/YYYY")}<br/>`
          : ""
      } else {
        tmpNoteMessage = !this.form.infoObtained.includes("estimate")
          ? `Meter Added - ${meterNumber} <br/>
                                      Supplier - ${this.selectedSupplier.supplierName
          } <br/>
                                      CED - ${dayjs(
            this.form.contractEndDate
          ).format("DD/MM/YYYY")}<br/>`
          : ""
      }

      const isEstimate = this.form.infoObtained.includes("estimate")
        ? dayjs(this.form.contractEndDateEstimate).unix()
        : dayjs(this.form.contractEndDate).unix()
      const noteMessage = this.form.infoObtained.includes("estimate")
        ? `Meter Added - Estimate <br/>
                                    Supplier - ${this.selectedSupplier.supplierName
        } <br/>
                                    CED - ${dayjs(
          this.form.contractEndDateEstimate
        ).format("DD/MM/YYYY")}`
        : tmpNoteMessage
      const supplyNumber =
        this.form.meterType === "9"
          ? this.supplyDetails.sId +
          this.supplyDetails.mpIdA +
          this.supplyDetails.mpIdB +
          this.supplyDetails.cd
          : this.supplyDetails.supplyNumber
      // const meterDetails = {
      //     meterId: 0,
      //     addressId: this.selectedAddress.addressId,
      //     estimateActual: this.form.infoObtained.includes('estimate') ? 'Estimate' : 'Actual',
      //     currentUserId: this.userId,
      //     meterTypeId: parseInt(this.form.meterType),
      //     pc:  this.supplyDetails.pc,
      //     mtc: this.supplyDetails.mtc,
      //     llf: this.supplyDetails.llf,
      //     supplyNumber: supplyNumber ? supplyNumber : "",
      //     supplierId: this.selectedSupplierId,
      //     ced: isNaN(isEstimate) ? 0 : isEstimate,
      //     noteMessage: noteMessage,
      //     companyId:this.$store.getters['company/companyId']
      // }

      // const formData = new FormData()

      // formData.append("File", this.file)
      // formData.append("Description", this.description)
      // formData.append("MeterDocumentTypeID", this.uploadSelected)
      // formData.append("FileID", 0)
      // formData.append("UploadedBy", this.userId)
      // formData.append("Valid", this.isSelectedDocumentValid())

      let dataObject = {
        meterID: 0,
        addressID: this.selectedAddress.addressId,
        estimateActual: this.form.infoObtained.includes("estimate") ? "Estimate" : "Actual",
        currentUserID: this.userId,
        meterTypeId: parseInt(this.form.meterType),
        pc: this.supplyDetails.pc,
        mtc: this.supplyDetails.mtc,
        llf: this.supplyDetails.llf,
        supplyNumber: supplyNumber ? supplyNumber : "",
        supplierID: this.selectedSupplierId,
        ced: isNaN(isEstimate) ? 0 : isEstimate,
        noteMessage: noteMessage,
        companyID: this.$store.getters["company/companyId"],
        valid: true,
        "description": "",
        "meterDocumentTypeID": 0,
        "meterFileID": 0,
      }


      try {
        const response = await api.post("saveMeterWithDocumentSubBroker", dataObject)
        const self = this
        if (response && response.status === 200) {
          if (isNaN(parseFloat(response.data))) {
            self.msgError = response.data
            //   somethingWentWrong()
          } else {
            changesSaved("Meter Successfully Added")
            this.meterId = response.data
            // this.uploadContract()
            setTimeout(() => {
              this.fetchMainData(this.companyId)
              this.fetchMetersAfterRemove(this.selectedAddress.addressId)
              this.fetchPrimaryMetersAfterSave(this.companyId)
              this.fetchNotes()
              this.resetForm()
              this.closeForm()
            }, 1000)
          }
        }
      } catch (error) {
        somethingWentWrong()
      }
    },
    async uploadContract() {
      const formData = new FormData()
      // const file = 0
      formData.append("ID", 0)
      formData.append("MeterID", this.meterId)
      formData.append("File", this.file)
      formData.append("CompanyID", this.$store.getters["company/companyId"])
      formData.append("Description", this.description)
      formData.append("MeterDocumentTypeID", this.uploadSelected)
      formData.append("UploadedBy", this.userId)

      const response = await api.post("uploadMeterDocument", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      if (response.status === 200) {
        this.description = ""
        this.uploadSelected = 0
        this.file = ""
        changesSaved("Document Successfully Uploaded!")
      }
    },
    selectSupplier(supplierId) {
      console.log("here")
      const supplier = this.suppliers.find(
        supplier => supplier.supplierId === supplierId
      )
      this.selectedSupplier = supplier
      this.fetchSuppliers()
    }
  }
}
</script>