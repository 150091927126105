var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",staticClass:"px-5",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"mt-4"},[_c('v-col',[_c('div',{staticClass:"mb-2"},[_vm._v("Meter Type")]),_c('v-btn-toggle',{model:{value:(_vm.form.meterType),callback:function ($$v) {_vm.$set(_vm.form, "meterType", $$v)},expression:"form.meterType"}},[_c('v-btn',{attrs:{"value":"9"},on:{"click":function($event){return _vm.showMetersForm(9)}}},[_c('v-icon',{attrs:{"color":"amber"}},[_vm._v("mdi-lightbulb")])],1),_c('v-btn',{attrs:{"value":"10"},on:{"click":function($event){return _vm.showMetersForm(10)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-fire")])],1),_c('v-btn',{attrs:{"value":"2705"},on:{"click":function($event){return _vm.showMetersForm(2705)}}},[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-water")])],1)],1)],1)],1),(_vm.showInfoButtons)?_c('v-row',[_c('v-col',[_c('div',{staticClass:"mb-2"},[_vm._v("Information Obtained")]),_c('v-btn-toggle',{attrs:{"tile":""},model:{value:(_vm.form.infoObtained),callback:function ($$v) {_vm.$set(_vm.form, "infoObtained", $$v)},expression:"form.infoObtained"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"value":"normal"},on:{"click":_vm.infoObtained}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check-bold")])],1)]}}],null,false,501478128)},[_c('span',[_vm._v("Actual")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"value":"estimate"},on:{"click":_vm.getInfo}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-help")])],1)]}}],null,false,413324546)},[_c('span',[_vm._v("Estimate")])])],1)],1)],1):_vm._e(),(_vm.showInfoObtained)?_c('v-row',[_c('v-col',[_c('SupplyNumber',{staticClass:"mb-2",attrs:{"type":_vm.form.meterType === '9'
        ? 'electricity'
        : _vm.form.meterType === '2705'
          ? 'water'
          : 'gas'}}),(_vm.form.meterType === '9' &&
        _vm.showSupplyNumberValidationError &&
        _vm.pcLengthError &&
        (Object.keys(_vm.supplyDetails).length === 0 ||
          !_vm.supplyDetails.pc ||
          (_vm.supplyDetails.pc && _vm.supplyDetails.pc.length < 2))
        )?_c('span',{staticClass:"mt-1 d-block"},[_c('span',{staticClass:"red--text"},[_vm._v(" PC length must be 2. ")])]):_vm._e(),(_vm.form.meterType === '9' &&
        _vm.showSupplyNumberValidationError &&
        _vm.mtcLengthError &&
        (Object.keys(_vm.supplyDetails).length === 0 ||
          !_vm.supplyDetails.mtc ||
          (_vm.supplyDetails.mtc && _vm.supplyDetails.mtc.length < 3))
        )?_c('span',{staticClass:"mt-1 d-block"},[_c('span',{staticClass:"red--text"},[_vm._v(" MTC length must be 3. ")])]):_vm._e(),(_vm.form.meterType === '9' &&
        _vm.showSupplyNumberValidationError &&
        _vm.llfLengthError &&
        (Object.keys(_vm.supplyDetails).length === 0 ||
          !_vm.supplyDetails.llf ||
          (_vm.supplyDetails.llf && _vm.supplyDetails.llf.length < 3))
        )?_c('span',{staticClass:"mt-1 d-block"},[_c('span',{staticClass:"red--text"},[_vm._v(" LLF length must be 3. ")])]):_vm._e(),(_vm.form.meterType === '9' && _vm.showLessThan13SupplyNumberValidationError
        )?_c('span',{staticClass:"mt-1 d-block"},[_c('span',{staticClass:"red--text"},[_vm._v(" Supply Number must be at least 13. ")])]):_vm._e(),(_vm.form.meterType !== '9' &&
        _vm.showSupplyNumberValidationError &&
        !_vm.isFullSupplyNumberEntered
        )?_c('span',{staticClass:"mt-2"},[_c('span',{staticClass:"red--text"},[_vm._v(" Please enter full supply number ")])]):_vm._e(),(_vm.form.meterType !== '9' &&
        _vm.showSupplyNumberValidationError &&
        _vm.isFullSupplyNumberEntered
        )?_c('span',{staticClass:"mt-2"},[_c('span',{staticClass:"red--text"},[_vm._v(" Please enter full supply number ")])]):_vm._e(),(_vm.showInvalidSupplyNumberFormatError && _vm.form.meterType !== '2705')?_c('span',{staticClass:"mt-2"},[_c('span',{staticClass:"red--text"},[_vm._v(" Invalid format entered. ")])]):_vm._e(),(_vm.showMinSupplyNumberValidationError)?_c('span',{staticClass:"mt-2"},[_c('span',{staticClass:"red--text"},[_vm._v(" Supply number must be at least 6 digits ")])]):_vm._e(),(_vm.msgError)?_c('span',{staticClass:"mt-2"},[_c('span',{staticClass:"red--text",domProps:{"innerHTML":_vm._s(_vm.msgError)}},[_vm._v(_vm._s(this.msgError))])]):_vm._e(),_c('v-select',{ref:"selectedSupplierId",staticClass:"mt-4",attrs:{"label":"Current Supplier","item-text":"text","item-value":"value","items":_vm.filteredSuppliers,"rules":_vm.supplierRules},on:{"change":function($event){return _vm.selectSupplier(_vm.selectedSupplierId)}},model:{value:(_vm.selectedSupplierId),callback:function ($$v) {_vm.selectedSupplierId=$$v},expression:"selectedSupplierId"}}),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],ref:"contractEndDate",staticClass:"d-inline-block mr-2",attrs:{"type":"tel","label":"Contract End Date","placeholder":"DD/MM/YYYY","rules":_vm.cedRules},on:{"keyup":_vm.contractEndDateChanged},model:{value:(_vm.contractEndDate),callback:function ($$v) {_vm.contractEndDate=$$v},expression:"contractEndDate"}}),_c('v-icon',_vm._g(_vm._b({staticClass:"d-inline-block mr-2"},'v-icon',attrs,false),on),[_vm._v(" mdi-calendar ")])]}}],null,false,330001765),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{ref:"picker",attrs:{"min":"1950-01-01","locale":"en-GB"},on:{"change":_vm.formContractEndDateChanged},model:{value:(_vm.form.contractEndDate),callback:function ($$v) {_vm.$set(_vm.form, "contractEndDate", $$v)},expression:"form.contractEndDate"}})],1)],1)],1):_vm._e(),(_vm.showGetInfo)?_c('v-row',[_c('v-col',[_c('v-select',{ref:"selectedSupplierId",attrs:{"items":_vm.filteredSuppliers,"label":"Current Supplier","item-text":"text","item-value":"value","rules":_vm.supplierRules},on:{"change":function($event){return _vm.selectSupplier(_vm.selectedSupplierId)}},model:{value:(_vm.selectedSupplierId),callback:function ($$v) {_vm.selectedSupplierId=$$v},expression:"selectedSupplierId"}}),_c('v-radio-group',{attrs:{"mandatory":"","row":""},model:{value:(_vm.radios),callback:function ($$v) {_vm.radios=$$v},expression:"radios"}},[_c('v-radio',{attrs:{"label":"End","value":"radio-1"},on:{"click":_vm.showEndDate}}),_c('v-radio',{attrs:{"label":"Start","value":"radio-2"},on:{"click":_vm.showStartDate}})],1),(_vm.showEnd)?_c('div',[_c('v-menu',{ref:"menuEnd",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"d-inline-block mr-2"},'v-icon',attrs,false),on),[_vm._v(" mdi-calendar ")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],ref:"contractEndDateEstimate",staticClass:"d-inline-block mr-2",attrs:{"type":"tel","label":"Contract End Date","placeholder":"DD/MM/YYYY","rules":_vm.cedRules},on:{"keyup":_vm.contractEndDateEstimateChanged},model:{value:(_vm.contractEndDateEstimate),callback:function ($$v) {_vm.contractEndDateEstimate=$$v},expression:"contractEndDateEstimate"}})]}}],null,false,4017468243),model:{value:(_vm.menuEnd),callback:function ($$v) {_vm.menuEnd=$$v},expression:"menuEnd"}},[_c('v-date-picker',{ref:"picker",attrs:{"min":"1950-01-01","locale":"en-GB"},on:{"change":_vm.formContractEndDateEstimateChanged},model:{value:(_vm.form.contractEndDateEstimate),callback:function ($$v) {_vm.$set(_vm.form, "contractEndDateEstimate", $$v)},expression:"form.contractEndDateEstimate"}})],1)],1):_vm._e(),(_vm.showStart)?_c('div',[_c('v-menu',{ref:"menuStart",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"d-inline-block mr-2"},'v-icon',attrs,false),on),[_vm._v(" mdi-calendar ")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],ref:"contractStartDate",staticClass:"d-inline-block mr-2",attrs:{"type":"tel","rules":_vm.csdRules,"label":"Contract Start Date","placeholder":"DD/MM/YYYY"},on:{"keyup":_vm.contractStartDateChanged},model:{value:(_vm.contractStartDate),callback:function ($$v) {_vm.contractStartDate=$$v},expression:"contractStartDate"}})]}}],null,false,3348400723),model:{value:(_vm.menuStart),callback:function ($$v) {_vm.menuStart=$$v},expression:"menuStart"}},[_c('v-date-picker',{ref:"picker",attrs:{"min":"1950-01-01","locale":"en-GB"},on:{"change":_vm.formContractStartDateChanged},model:{value:(_vm.form.contractStartDate),callback:function ($$v) {_vm.$set(_vm.form, "contractStartDate", $$v)},expression:"form.contractStartDate"}})],1),(_vm.showStart)?_c('div',[_c('v-text-field',{attrs:{"max":"60","type":"number","label":"Contract Term (Months)"},model:{value:(_vm.termInMonths),callback:function ($$v) {_vm.termInMonths=$$v},expression:"termInMonths"}}),_c('div',{staticClass:"mb-2"},[_vm._v("Contract End Date")]),_c('v-text-field',{attrs:{"disabled":""},model:{value:(_vm.ced),callback:function ($$v) {_vm.ced=$$v},expression:"ced"}})],1):_vm._e()],1):_vm._e()],1)],1):_vm._e(),(_vm.showInfoObtained || _vm.showGetInfo)?_c('div',{staticClass:"mt-4"},[_c('v-btn',{attrs:{"depressed":"","color":"accent","block":"","dark":"","large":""},on:{"click":_vm.validateAndSubmitForm}},[_vm._v(" Add Meter ")])],1):_vm._e(),_c('v-row')],1)}
var staticRenderFns = []

export { render, staticRenderFns }